import axios from "axios";
import { PageContent } from "../Model/PageContent.model";

function filterByTitle(dataList: PageContent[], titleForFiltering: string) {
  return dataList?.find((data: any) =>
    data.header?.includes(titleForFiltering)
  );
}

export async function getContentByTitle(title: string): Promise<PageContent> {
  const allData = await getAllContent();
  const foundPageContent = filterByTitle(allData, title);
  return foundPageContent ?? { header: "", body: "" };
}

export async function getAllContent(): Promise<PageContent[]> {
  const response = await axios.get(
    `${process.env.REACT_APP_PAGE_CONTENT_API}/services-page-content.json`
  );
  return response?.data?.map((d: PageContent) => ({
    ...d,
    body: d?.body?.replaceAll("{{app_name}}", process.env.REACT_APP_NAME ?? ""),
  }));
}

export async function getAllTestimonials(): Promise<any> {
  return axios.get(
    `${process.env.REACT_APP_PAGE_CONTENT_API}/testimonials.json`
  );
}
