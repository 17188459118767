import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  AppShell,
  Burger,
  Button,
  Container,
  Divider,
  Drawer,
  Group,
  List,
  Menu,
  rem,
  Stack,
  Title,
  useMantineTheme,
  Text,
} from "@mantine/core";
import { Outlet, useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { HugeTitle } from "./Components/Shared/HugeTitle";
import {
  IconBook,
  IconFileText,
  IconFlask,
  IconPresentation,
  IconClipboardCheck,
  IconSchool,
  IconLayout as IconProject,
  IconSearch,
  IconHelpCircle,
  IconChalkboard,
  IconBrandWhatsapp,
  IconPhoneRinging,
} from "@tabler/icons-react";
import { sendWhatsAppMessage } from "./Servives/contact.service";

interface NavigationItem {
  title: string;
  prominent?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
  children?: NavigationItem[];
}

const navigationMenuItems: NavigationItem[] = [
  {
    title: "Services",
    children: [
      {
        title: "Dissertation - Any Subject",
        icon: <IconBook style={{ width: "14px", height: "14px" }} />,
        onClick: () => console.log("Dissertation - Any Subject"),
      },
      {
        title: "Research Paper Writing - Any Subject",
        icon: <IconFileText style={{ width: "14px", height: "14px" }} />,
        onClick: () => console.log("Research Paper Writing selected"),
      },
      {
        title: "Lab Report - Any Subject",
        icon: <IconFlask style={{ width: "14px", height: "14px" }} />,
        onClick: () => console.log("Lab Report selected"),
      },
      {
        title: "Presentation Writing Service - Any Subject",
        icon: <IconPresentation style={{ width: "14px", height: "14px" }} />,
        onClick: () => console.log("Presentation Writing Service selected"),
      },
      {
        title: "Do My Assignment - Any Subject",
        icon: <IconClipboardCheck style={{ width: "14px", height: "14px" }} />,
        onClick: () => console.log("Do My Assignment selected"),
      },
      {
        title: "Do My Homework - Any Subject",
        icon: <IconSchool style={{ width: "14px", height: "14px" }} />,
        onClick: () => console.log("Do My Homework selected"),
      },
      {
        title: "Help Me with Final Year Project - Any Subject",
        icon: <IconProject style={{ width: "14px", height: "14px" }} />,
        onClick: () => console.log("Help with Final Year Project selected"),
      },
      {
        title: "Let’s Research - Any Subject",
        icon: <IconSearch style={{ width: "14px", height: "14px" }} />,
        onClick: () => console.log("Let's Research selected"),
      },
      {
        title: "Online Assignment - Any Subject",
        icon: <IconHelpCircle style={{ width: "14px", height: "14px" }} />,
        onClick: () => console.log("Online Assignment selected"),
      },
      {
        title: "Online Tutoring - Any Subject",
        icon: <IconChalkboard style={{ width: "14px", height: "14px" }} />,
        onClick: () => console.log("Online Tutoring selected"),
      },
    ],
  },
  // {
  //   title: "Subjects",
  //   onClick: () => console.log("Subjects clicked"),
  // },
  // {
  //   title: "Blogs",
  //   onClick: () => console.log("Blogs clicked"),
  // },
  {
    title: "Reviews",
    onClick: () => console.log("Reviews clicked"),
  },
  {
    title: "WhatsApp us",
    icon: <IconBrandWhatsapp />,
    prominent: true,
    onClick: () =>
      sendWhatsAppMessage("Hello, I need help with my assignment!"),
  },
];

function Layout() {
  const theme = useMantineTheme();
  const [opened, { toggle }] = useDisclosure();
  const [expandedNavigationItemId, setExpandedNavigationItemId] =
    useState<any>(-1);

  function onPhoneNumberClick() {
    window.location.href = `tel:${process.env.REACT_APP_PHNO}`;
  }

  function createNavigationItems({ forDesktop = false }) {
    if (forDesktop) {
      return navigationMenuItems.map(
        ({ title, onClick, children, prominent, icon }: NavigationItem) => {
          if (!children?.length) {
            return (
              <Button
                variant={prominent ? "filled" : "subtle"}
                size="md"
                onClick={onClick}
                leftSection={icon}
              >
                {title}
              </Button>
            );
          } else {
            return (
              <Menu shadow="md" width={"auto"} withArrow arrowPosition="side">
                <Menu.Target>
                  <Button
                    variant={prominent ? "filled" : "subtle"}
                    leftSection={icon}
                    size="md"
                  >
                    {title}
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Label> {title}</Menu.Label>
                  {children.map(
                    (
                      navigationMenuItemChild: NavigationItem,
                      index: number
                    ) => (
                      <Menu.Item
                        key={"desktop-children-" + index}
                        leftSection={navigationMenuItemChild.icon}
                        onClick={navigationMenuItemChild.onClick}
                        component="a"
                        href={`/${navigationMenuItemChild.title}`}
                      >
                        {navigationMenuItemChild.title}
                      </Menu.Item>
                    )
                  )}
                </Menu.Dropdown>
              </Menu>
            );
          }
        }
      );
    } else {
      return (
        <Stack>
          {navigationMenuItems.map(
            (
              { title, onClick, children, prominent, icon }: NavigationItem,
              index: number
            ) => {
              if (!children?.length) {
                return (
                  <Stack key={"mobile-" + index} style={{ width: "100%" }}>
                    <Button
                      variant={prominent ? "filled" : "subtle"}
                      size="md"
                      onClick={onClick}
                      fullWidth
                      leftSection={icon}
                    >
                      {title}
                    </Button>
                    <Divider />
                  </Stack>
                );
              } else {
                return (
                  <Stack key={"mobile-" + index} style={{ width: "100%" }}>
                    <Button
                      fullWidth
                      variant={prominent ? "filled" : "subtle"}
                      size="md"
                      onClick={() => {
                        setExpandedNavigationItemId((currentIndex: any) => {
                          if (currentIndex === index) return -1;
                          else return index;
                        });
                        if (onClick) onClick();
                      }}
                    >
                      {title}
                    </Button>
                    {expandedNavigationItemId === index && (
                      <Stack gap={8}>
                        {children.map(
                          (
                            navigationMenuItemChild: NavigationItem,
                            childIndex: number
                          ) => (
                            <Button
                              variant="subtle"
                              size="sm"
                              leftSection={navigationMenuItemChild.icon}
                              key={"mobile-" + childIndex + "-child"}
                              onClick={onClick}
                              component="a"
                              href={`/${navigationMenuItemChild.title}`}
                            >
                              {navigationMenuItemChild.title}
                            </Button>
                          )
                        )}
                      </Stack>
                    )}
                    <Divider />
                  </Stack>
                );
              }
            }
          )}
        </Stack>
      );
    }
  }

  return (
    <>
      <AppShell
        header={{ height: 100 }}
        navbar={{
          width: 300,
          breakpoint: "sm",
          collapsed: { desktop: true, mobile: !opened },
        }}
        padding="md"
      >
        <AppShell.Header>
          <Group h="100%">
            {/* <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" /> */}
            <Container style={{ width: "100%" }} fluid>
              <Group justify="space-between" align="center" w={"100%"} flex={1}>
                <Group visibleFrom="md">
                  <HugeTitle style={{ fontSize: "2.5rem" }}>
                    {process.env.REACT_APP_NAME}
                  </HugeTitle>
                </Group>

                <Group hiddenFrom="md">
                  <HugeTitle style={{ fontSize: "2rem" }}>
                    {process.env.REACT_APP_NAME}
                  </HugeTitle>
                </Group>
                <Group gap={"xs"} visibleFrom="md">
                  {createNavigationItems({ forDesktop: true })}
                </Group>
                <Group hiddenFrom="md">
                  <Burger
                    opened={opened}
                    onClick={toggle}
                    aria-label="Toggle Drawer"
                  />

                  <Drawer
                    opened={opened}
                    onClose={toggle}
                    position="right"
                    offset={10}
                    radius={"md"}
                    size="100%"
                  >
                    {createNavigationItems({ forDesktop: false })}
                  </Drawer>
                </Group>
              </Group>
            </Container>
          </Group>
          <Container
            fluid
            p={10}
            style={{ background: theme.colors.green[7] }}
            onClick={onPhoneNumberClick}
          >
            <Group
              align="center"
              justify="center"
              gap={7}
              style={{ cursor: "pointer" }}
            >
              <Text c="#fff" fw="bold" style={{ cursor: "pointer" }}>
                Lets connect at
              </Text>
              <IconPhoneRinging
                color="#fff"
                style={{
                  strokeWidth: rem(2),
                  width: rem(20),
                  height: rem(20),
                  cursor: "pointer",
                }}
              />
              <Text c="#fff" fw="bold" style={{ cursor: "pointer" }}>
                {process.env.REACT_APP_PHNO}
              </Text>
            </Group>
          </Container>
        </AppShell.Header>
        <AppShell.Main px={0} pb={0} style={{ position: "relative" }}>
          <Outlet />

          <Button
            radius={"md"}
            size="md"
            style={{
              position: "fixed",
              bottom: "0%",
              right: "2%",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
            leftSection={<IconBrandWhatsapp />}
            onClick={() => sendWhatsAppMessage("")}
          >
            WhatsApp us
          </Button>
        </AppShell.Main>
      </AppShell>
    </>
  );
}

export default Layout;
