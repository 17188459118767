import {
  Group,
  Stack,
  Title,
  Text,
  Grid,
  Card,
  TextInput,
  Button,
  Container,
  Select,
  useMantineTheme,
  Divider,
  Stepper,
  Box,
  Image,
  Paper,
  Chip,
} from "@mantine/core";

import { isNotEmpty, useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { HugeTitle } from "./Shared/HugeTitle";
import emailjs from "@emailjs/browser";
import AlertModal from "./Shared/AlertModal";
import { useDisclosure } from "@mantine/hooks";
import { getAllContent, getContentByTitle } from "../Servives/content.service";
import { useParams } from "react-router-dom";
import { PageContent } from "../Model/PageContent.model";
import Testimonials from "./Testimonials";
import SectionHeader from "./Shared/SectionHeader";

interface ServicesData {
  services: PageContent[];
  defaultService: PageContent | null;
}

function Home() {
  const { title } = useParams();
  const [pageContent, setPageContent] = useState<PageContent>();
  const [services, setServices] = useState<ServicesData>({
    services: [],
    defaultService: null,
  });

  async function loadContent(pageTitle: string) {
    try {
      const res = await getContentByTitle(pageTitle);
      // if (res?.data) {
      //   setPageContent(res.data);
      // }
      setPageContent(res);
      setServices((curr) => ({ ...curr, defaultService: res }));
    } catch (e) {
      console.log(e);
    }
  }

  async function loadServicesList() {
    try {
      const res = await getAllContent();
      // if (res?.data) {
      //   setPageContent(res.data);
      // }
      setServices({
        services: res ?? [],
        defaultService: null,
      });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    console.log(title);
    if (title) loadContent(title);
  }, [title]);

  useEffect(() => {
    loadServicesList();
  }, []);

  return (
    <>
      <Container mt={100} fluid px={0} mx={0}>
        <Stack gap={80}>
          <Feature pageContent={pageContent} serviceData={services} />
          <Testimonials />
          <OurService />
          <OurPromise />
        </Stack>
      </Container>
      <Footer />
    </>
  );
}

interface FeatureProps {
  pageContent?: PageContent;
  serviceData?: ServicesData;
}

function Feature({ pageContent, serviceData }: FeatureProps) {
  const [isEmailSent, { open: emailSent, close: emailNotSent }] =
    useDisclosure(false);
  const [isSendingEmail, { open: setSendingEmail, close: hasSentEmail }] =
    useDisclosure(false);
  const [
    shouldShowAlertModal,
    { open: showAlertModal, close: closeAlertModal },
  ] = useDisclosure(false);

  const form = useForm<any>({
    initialValues: {
      service: "",
      title: "",
      contact: "",
    },

    validate: {
      service: isNotEmpty("Please select a service"),
      title: isNotEmpty("Project title is required"),
      contact: (value: any) =>
        /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value) || /^[\d\s+-]+$/.test(value)
          ? null
          : "Enter a valid email or WhatsApp number",
    },
  });

  async function sendMail(title: string, service: string, contact: string) {
    setSendingEmail();
    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string,
        {
          service_type: service,
          project_title: title,
          contact_info: contact,
        }
      );
      emailSent();
      form.reset();
    } catch (e) {
      console.log(e);
      emailNotSent();
    } finally {
      hasSentEmail();
      showAlertModal();
    }
  }

  const handleSubmit = (values: typeof form.values) => {
    sendMail(values.title, values.service, values.contact);
  };

  useEffect(() => {
    form.setValues({
      service: serviceData?.defaultService
        ? serviceData?.defaultService?.header
        : "",
    });
  }, [serviceData?.defaultService]);

  return (
    <Container>
      <AlertModal
        opened={shouldShowAlertModal}
        closeModal={closeAlertModal}
        hasError={!isEmailSent}
      >
        <Text component="p">
          {isEmailSent ? (
            <>
              Your request is in safe hands. Our team is reviewing it and will
              get back to you ASAP—promise! 💼. <br />
              <br />
              In the meantime, sit back, relax, and let us handle the rest. 😊
            </>
          ) : (
            <>
              We couldn’t send your request at the moment. Don’t worry, though!
              Try again in a few hours, and we’ll make sure to get back to you
              ASAP.
              <br /> <br />
              If it’s urgent, feel free to reach us on WhatsApp—we’re always
              ready to help! 📱
            </>
          )}
        </Text>
      </AlertModal>
      <Grid gutter={30} grow>
        <Grid.Col span={{ base: 12, md: 6 }}>
          {pageContent ? (
            <Stack
              gap={9}
              style={{ width: "100%", height: "100%" }}
              justify="center"
            >
              <HugeTitle>
                <div
                  dangerouslySetInnerHTML={{ __html: pageContent.header }}
                ></div>
              </HugeTitle>
              <DescriptionText size="lg">
                <div
                  dangerouslySetInnerHTML={{ __html: pageContent.body }}
                ></div>
              </DescriptionText>
            </Stack>
          ) : (
            <Stack
              gap={15}
              style={{ width: "100%", height: "100%" }}
              justify="center"
            >
              <HugeTitle>Ace Your Assignments with Expert Help!</HugeTitle>
              <DescriptionText size="lg">
                Welcome to{" "}
                <strong style={{ color: "#014d40" }}>
                  {process.env.REACT_APP_NAME}
                </strong>
                , the go-to platform trusted by students around the globe for
                reliable and expert assignment help. We pride ourselves on
                delivering{" "}
                <Chip
                  style={{ fontWeight: "bold", display: "inline-block" }}
                  defaultChecked
                  color="blue"
                  variant="light"
                  mt={7}
                >
                  100% Original
                </Chip>{" "}
                <Chip
                  style={{ fontWeight: "bold", display: "inline-block" }}
                  defaultChecked
                  color="green"
                  variant="light"
                  mt={7}
                >
                  AI-free
                </Chip>{" "}
                <Chip
                  style={{ fontWeight: "bold", display: "inline-block" }}
                  defaultChecked
                  color="orange"
                  mt={7}
                  variant="light"
                >
                  Plagiarism free
                </Chip>{" "}
                content crafted by top-rated professionals. Your{" "}
                <Chip
                  style={{ fontWeight: "bold", display: "inline-block" }}
                  defaultChecked
                  color="teal"
                  variant="light"
                  mt={7}
                >
                  Privacy
                </Chip>{" "}
                is always protected, ensuring complete
                <Chip
                  style={{ fontWeight: "bold", display: "inline-block" }}
                  defaultChecked
                  color="violet"
                  variant="light"
                  mt={7}
                >
                  Confidentiality
                </Chip>{" "}
                every step of the way.
              </DescriptionText>
            </Stack>
          )}
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }} pr={{ base: 0, md: 3 }}>
          <form
            onSubmit={form.onSubmit(handleSubmit)}
            style={{ height: "100%", width: "100%" }}
          >
            <Stack
              style={{ width: "100%", height: "100%" }}
              justify="center"
              gap={20}
            >
              <Select
                size="lg"
                placeholder="Choose Service"
                data={serviceData?.services?.map(
                  (service: PageContent) => service.header
                )}
                clearable
                searchable
                maxDropdownHeight={500}
                {...form.getInputProps("service")}
                disabled={isSendingEmail}
              />

              <TextInput
                size="lg"
                placeholder="Project Title"
                {...form.getInputProps("title")}
                disabled={isSendingEmail}
              />

              <TextInput
                size="lg"
                placeholder="Your Email/Whatsapp Number"
                {...form.getInputProps("contact")}
                disabled={isSendingEmail}
              />

              <Button size="lg" type="submit" loading={isSendingEmail}>
                Get Help
              </Button>
            </Stack>
          </form>
        </Grid.Col>
      </Grid>
    </Container>
  );
}


const StepperLabel = ({ title, description }: any) => {
  return (
    <Stack pt={0} mb={50} mt={0}>
      <HugeTitle style={{ fontSize: "2rem" }}>{title}</HugeTitle>
      <DescriptionText>{description}</DescriptionText>
    </Stack>
  );
};

function OurPromise() {
  const theme = useMantineTheme();
  return (
    <>
      <SectionHeader>
        <HugeTitle style={{ fontSize: "2.5rem" }}>We Promise</HugeTitle>
      </SectionHeader>
      <Container>
        {/* <HugeTitle  style={{ fontSize: "2rem" }}>
          We Promise
        </HugeTitle> */}

        <Stepper
          active={Number.MAX_SAFE_INTEGER}
          onStepClick={() => {}}
          orientation="vertical"
          color={theme.colors.green[9]}
        >
          <Stepper.Step
            label={
              <StepperLabel
                title="Step-by-Step Expert Guidance"
                description="Our team of expert tutors provides clear, detailed explanations to help you conquer your assignments with ease."
              />
            }
          />
          <Stepper.Step
            label={
              <StepperLabel
                title="100% Authentic, Plagiarism-Free Work"
                description="We guarantee customized, error-free solutions that are completely original, ensuring your work stands out."
              />
            }
          />
          <Stepper.Step
            label={
              <StepperLabel
                title="Unlimited Revisions for Perfection"
                description="We offer unlimited revisions to ensure your assignment is perfect and meets your exact needs—no compromises."
              />
            }
          />
          <Stepper.Step
            label={
              <StepperLabel
                title="Boost Your Grades with Expert Support"
                description={
                  "With " +
                  process.env.REACT_APP_NAME +
                  ", you get the guidance you need to improve your grades and unlock your academic potential."
                }
              />
            }
          />
          <Stepper.Step
            label={
              <StepperLabel
                title="Risk-Free Money-Back Guarantee"
                description="If you're not fully satisfied with our work, we offer a hassle-free full refund—no questions asked."
              />
            }
          />
          <Stepper.Step
            label={
              <StepperLabel
                title="Guaranteed On-Time Delivery"
                description="We pride ourselves on punctuality, ensuring your project is delivered exactly when you need it—never late."
              />
            }
          />
        </Stepper>
      </Container>
    </>
  );
}

function OurService() {
  const theme = useMantineTheme();
  const hugeTitleStyle = {
    fontSize: "1.5rem",
  };
  const descriptionTextStyle = { fontSize: "1rem", fontWeight: "400" };

  const services = [
    {
      image: "1.jpg",
      title: "Writing and Research Assistance",
      description:
        "Struggling with dissertations, research papers, or in-depth academic tasks? We provide expertly written, plagiarism-free content tailored to your subject. From topic selection to final submission, we ensure excellence and originality.",
    },
    {
      image: "2.jpg",
      title: "Reports and Projects",
      description:
        "Whether it’s precise lab reports or comprehensive guidance on final-year projects, we deliver detailed, accurate, and well-structured solutions to meet your academic needs.",
    },
    {
      image: "3.jpg",
      title: "Presentation and Homework Support",
      description:
        "Captivate your audience with impactful presentations or ease your workload with reliable homework and assignment assistance. We ensure timely delivery and professional results across all subjects.",
    },
    {
      image: "4.jpg",
      title: "Personalized Learning",
      description:
        "Take charge of your education with our personalized tutoring. Our one-on-one online sessions make complex topics easy to understand, helping you achieve your academic goals.",
    },
  ];

  return (
    <>
      <SectionHeader>
        <HugeTitle style={{ fontSize: "2.5rem" }}>Services</HugeTitle>
      </SectionHeader>
      <Container>
        <Grid gutter={"xl"} grow>
          {services.map((service) => (
            <Grid.Col span={{ base: 12, md: 4 }}>
              <Paper radius={0} style={{ width: "100%" }}>
                <Image
                  src={require(`../assets/images/${service.image}`)}
                  height={280}
                  alt="service-image"
                  style={{
                    width: "100%",
                  }}
                  fit="cover"
                />

                <Stack mt={10} p={3} gap={0}>
                  <HugeTitle style={hugeTitleStyle}>{service.title}</HugeTitle>
                  <DescriptionText style={descriptionTextStyle}>
                    <p>{service.description}</p>
                  </DescriptionText>
                </Stack>
              </Paper>
            </Grid.Col>
          ))}
        </Grid>
      </Container>
    </>
  );
}

function DescriptionText({ children, ...props }: any) {
  return (
    <Text lh={1.5} style={{ fontWeight: 100 }} size="lg" {...props}>
      {children}
    </Text>
  );
}

export default Home;

