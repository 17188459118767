import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Sample from "./Sample";
import {
  AppShell,
  Burger,
  Button,
  Container,
  Group,
  Title,
} from "@mantine/core";
import { Outlet, Route, Routes } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import Layout from "./Layout";
import Home from "./Components/Home";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({ duration: 800, offset: 300 });
  }, []);

  const [opened, { toggle }] = useDisclosure();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path=":title" element={<Home />} />
      </Route>
    </Routes>
  );
}

export default App;
