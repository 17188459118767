import {
  Avatar,
  Card,
  Container,
  Grid,
  Group,
  rem,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import {
  IconRosetteDiscountCheckFilled,
  IconStarFilled,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import SectionHeader from "./Shared/SectionHeader";
import { HugeTitle } from "./Shared/HugeTitle";
import { Testimonial } from "../Model/Testimonial.model";
import { getAllTestimonials } from "../Servives/content.service";
import { format } from "date-fns";

function Testimonials() {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  async function loadAllTestimonials() {
    try {
      const res = await getAllTestimonials();
      if (res?.data) {
        console.log(res?.data);
        setTestimonials(res?.data);
      } else {
        console.log("No testimonials");
      }
    } catch (e) {
      console.log("Unable to load testimonials");
    }
  }

  useEffect(() => {
    loadAllTestimonials();
  }, []);

  return (
    <>
      <SectionHeader>
        <HugeTitle style={{ fontSize: "2.5rem" }}>Reviews</HugeTitle>
      </SectionHeader>
      <Container>
        {testimonials?.length > 0 ? (
          <Grid
            gutter={{
              base: 20,
              md: 40,
            }}
          >
            {testimonials.map((testimonial: Testimonial) => (
              <Grid.Col
                span={{
                  base: 12,
                  md: 6,
                }}
              >
                <TestimonialsCard testimonial={testimonial} />
              </Grid.Col>
            ))}
          </Grid>
        ) : (
          <>Loading...</>
        )}
      </Container>
    </>
  );
}

interface TestimonialsCardProps {
  testimonial: Testimonial;
}

function TestimonialsCard({ testimonial }: TestimonialsCardProps) {
  const theme = useMantineTheme();

  function convertNameToTag(name: string) {
    return `@${name.replaceAll(" ", "").toLowerCase()}`;
  }

  function createRatings(userRating: number) {
    const acc = [];
    const rating = Math.ceil(userRating);
    for (let i = 0; i < rating; i++) {
      acc.push(<IconStarFilled size={"1rem"} color={theme.colors.yellow[7]} />);
    }
    return <Group gap={3}>{acc}</Group>;
  }

  return (
    <Card
      shadow={"sm"}
      radius={"md"}
      //style={{ background: "rgb(50 50 50)", color: "white" }}
      data-aos="fade-up"
    >
      <Stack>
        <Stack gap={15}>
          <Group>
            <Avatar
              color={theme.colors.green[8]}
              radius="xl"
              name={testimonial.name}
              size={"lg"}
              variant="filled"
            />

            <Stack gap={1}>
              <Group gap={10}>
                <Text size="md" fw={550}>
                  {testimonial.name}
                </Text>
                <IconRosetteDiscountCheckFilled
                  size={"1.2rem"}
                  color={theme.colors.green[8]}
                  stroke={rem(0.5)}
                />
              </Group>

              <Text size="sm" c="dimmed" fw={500}>
                {convertNameToTag(testimonial.name)}
              </Text>
            </Stack>
          </Group>

          <Text
            style={{ fontSize: "0.95rem", lineHeight: "1.7rem" }}
            c="dimmed"
          >
            <div
              dangerouslySetInnerHTML={{ __html: testimonial.comment }}
            ></div>
          </Text>
        </Stack>

        <Group justify="space-between" align="center">
          {createRatings(testimonial.rating)}
          <Text size="sm" c="dimmed" fw={512}>
            {format(testimonial.datedOn, "MMMM, yyyy")}
          </Text>
        </Group>
      </Stack>
    </Card>
  );
}

export default Testimonials;
