import { Box, Group, useMantineTheme } from "@mantine/core";
import { useEffect } from "react";

function SectionHeader({ children }: any) {
  const theme = useMantineTheme();
  return (
    <Box
      style={{
        background: theme.colors.green[0],
        width: "100%",
        borderRadius: theme.radius.xs,
      }}
      py={"3.5rem"}
      px={0}
      mx={0}
    >
      <Group align="center" justify="center">
        {children}
      </Group>
    </Box>
  );
}

export default SectionHeader;
