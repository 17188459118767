import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import React from "react";

interface AlertModalProps {
  opened: boolean;
  closeModal: () => void;
  children?: any;
  hasError?: boolean;
}

function AlertModal({
  opened,
  closeModal,
  children,
  hasError,
}: AlertModalProps) {
  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      title={
        <Text fw={510} size={"lg"} pl={5} c={!!hasError ? "red" : "#000"}>
          {!!hasError ? "Oops" : "Heads Up !"}
        </Text>
      }
      withCloseButton={false}
    >
      <Stack p={5}>
        <Group w={"100%"} justify="flex-end">
          {children}
          <Button onClick={closeModal}>Ok</Button>
        </Group>
      </Stack>
    </Modal>
  );
}


export default AlertModal;
