export const sendWhatsAppMessage = (message: string): void => {
  // Encode the message to ensure special characters are properly handled
  const encodedMessage = encodeURIComponent(message);

  // Construct the WhatsApp URL
  const whatsappUrl = `https://wa.me/${process.env.REACT_APP_WHATSAPP_PHNO}?text=${encodedMessage}`;

  // Open the URL in a new tab
  window.open(whatsappUrl, "_blank");
};
